import React from 'react'

import { Link } from 'gatsby'
import { GatsbyImage, getImage, StaticImage } from "gatsby-plugin-image"

import { format } from 'date-fns'

import { getPageUrl } from '../lib/helpers'

import AuthorImages from '../components/author-images'

import * as styles from './blog-post-preview.module.css'
//import { responsiveTitle3 } from './typography.module.css'

function BlogPostPreview(props) {
  return (
    <Link
      className={props.isInList ? styles.inList : styles.inGrid}
      to={getPageUrl(props)}
    >
      <div className={styles.leadMediaThumb}>
        {
          props.main_image ? 
          <GatsbyImage image={getImage(props.main_image?.imageFile)} alt={props.main_image.alternativeText ? props.main_image.alternativeText : props.title} />
          : <StaticImage src="../images/placeholder.png" alt=""/>
        }
      </div>
      <div className={styles.text}>
        { props.primary_topic &&
          <span className={ styles.category }>{props.primary_topic}</span>
        }
        { props.parent && !props.primary_topic &&
          <span className={ styles.category }>{props.parent.title_short}</span>
        }
        <span className={ styles.title}>{props.title}</span>
        {props.excerpt && (
          <div className={styles.excerpt}>
            {props.excerpt}
          </div>
        )}
        { props.authors && props.authors.length>0 &&
            <AuthorImages authors={props.authors}/>
        }
        { props.created_at && 
            <>Published {format( Date.parse(props.created_at), "MMMM do, yyyy")}</>
        }
        { props.updated_at && props.created_at && (Date.parse(props.updated_at)-60*60*24 > Date.parse(props.created_at)) && 
            <> Updated {format( Date.parse(props.updated_at), "MMMM do, yyyy")}</>
        }
      </div>
    </Link>
  )
}

export default BlogPostPreview
