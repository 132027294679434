import React from 'react'

import {Link} from 'gatsby'
import { GatsbyImage, StaticImage } from "gatsby-plugin-image"

import {getPageUrl} from '../lib/helpers'

import * as styles from './related-pages.module.css'

function RelatedPages (props) {
  return (
    <div className={styles.root}>
        {props.title && (<span className={styles.headline}>{props.title}</span>)}
        <div className={styles.related_pages}>
          <div className={styles.hScroll}>
            <ul className={styles.grid}>
            {props.nodes.slice(0, 5).map(post => post && (
                <li key={`${post.id}_${post.slug}`} className={styles.listItem}>
                  <Link to={getPageUrl(post)} className={styles.link}>
                    {
                      post.main_image ? 
                      <GatsbyImage image={post.main_image?.imageFile?.childImageSharp?.gatsbyImageData} alt={post.main_image.alternativeText ? post.main_image.alternativeText : post.title} />
                      : <StaticImage src="../images/placeholder.png" alt="" />
                    }
                    <div>{post.title}</div>
                  </Link>
                </li>
            ))}
            </ul>
          </div>
        </div>
    </div>
  )
}

RelatedPages.defaultProps = {
  title: "Related Pages",
  nodes: []
}

export default RelatedPages
